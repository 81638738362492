
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import CountriesTable from '@/components/pages/services/countries/countries/CountriesTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SenderCountries } from '@/definitions/services/countries/types'
import { getTableData } from '@/services/tableService'
import ComposeFormLine from '@/components/shared/compose/ComposeFormLine.vue'
import RecipientsEditor from '@/components/shared/compose/RecipientsEditor.vue'

export default defineComponent({
  name: 'SurveyRecipientsModal',
  components: {
    RecipientsEditor,
    ComposeFormLine,
    CountriesTable,
    TmButton,
    TmModal,
  },
  setup() {
    const [mock] = getTableData('servicesCountries')
    const myCountry: SenderCountries[] = mock.myCountry
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Destination', value: 'destination-slot', width: '150px' },
      { text: 'Default sender', value: 'defaultSender' },
      { text: 'Type', value: 'type-slot' },
      { text: '', value: 'buy-number-slot', hideSortable: true },
    ])

    return {
      tableHeaders,
      myCountry,
    }
  },
})
